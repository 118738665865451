import React from "react"
import classNames from "classnames"

import Fancybox from "../../../../../components/_V3/Fancybox"
import Slider from "../../../../../components/_V3/SecretPages/Slider"

import PromptIconOne from "./assets/PromptIconOne.webp"
import PromptIconTwo from "./assets/PromptIconTwo.webp"
import PromptIconThree from "./assets/PromptIconThree.webp"
import PromptIconFour from "./assets/PromptIconFour.webp"
import PromptIconFive from "./assets/PromptIconFive.webp"
import PromptIconSix from "./assets/PromptIconSix.webp"
import PromptIconSeven from "./assets/PromptIconSeven.webp"

import PromptIconEight from "./assets/PromptIconEight.webp"

import MediaContentOne from "./assets/MediaContentOne.webp"
import MediaContentTwo from "./assets/MediaContentTwo.webp"
import MediaContentThree from "./assets/MediaContentThree.webp"
import MediaContentFour from "./assets/MediaContentFour.webp"
import MediaContentFive from "./assets/MediaContentFive.webp"
import MediaContentSix from "./assets/MediaContentSix.webp"
import MediaContentSeven from "./assets/MediaContentSeven.webp"
import MediaContentEight from "./assets/MediaContentEight.webp"

import SlideContentOne from "./assets/SlideContentOne.webp"
import SlideContentTwo from "./assets/SlideContentTwo.webp"
import SlideContentThree from "./assets/SlideContentThree.webp"

import styles from "./styles.module.scss"

export const headingTextareaAndPrompt = [
	{
		heading: "Кофейный бизнес появился в моей жизни случайно",
		textarea: (
			<>
				Я&nbsp;был спортсменом, и&nbsp;когда моя спортивная карьера пошла
				на&nbsp;спад, старшие товарищи сказали&nbsp;&mdash; &laquo;надо делать
				что-то ещё&raquo;. Они&nbsp;же предложили продавать кофе в&nbsp;зёрнах
				и&nbsp;листовой чай и&nbsp;дали на&nbsp;старт 800&nbsp;000&nbsp;рублей.
				Я&nbsp;взял партнёра&nbsp;&mdash; Елену Глебову, с&nbsp;которой
				тренировались в&nbsp;одном зале. В&nbsp;2005 году мы&nbsp;открыли первый
				отдел по&nbsp;продаже чая и&nbsp;кофе в&nbsp;Благовещенске. За&nbsp;год
				мы&nbsp;просадили все деньги. Бизнес-партнёры сказали, что мне лучше
				не&nbsp;заниматься бизнесом, и&nbsp;предложили закрыть проект.
				Я&nbsp;не&nbsp;привык проигрывать и&nbsp;предложил дать мне возможность
				выкупить его.
			</>
		),
	},
	{
		heading: "Чтобы выкупить бизнес, мне дали год  ",
		textarea: (
			<>
				Первое, что я&nbsp;сделал после тех переговоров,&nbsp;&mdash; пошёл
				в&nbsp;книжный магазин. Попросил дать мне почитать &laquo;что-нибудь
				по&nbsp;бизнесу&raquo;. Мне принесли запылённую книгу&nbsp;&mdash;
				единственную, которая у&nbsp;них была. Что-то со&nbsp;словом
				&laquo;менеджмент&raquo; в&nbsp;названии. Мы&nbsp;начали выстраивать
				бизнес, основываясь на&nbsp;том, что было написано в&nbsp;книге. Это
				было время экспериментов. Изначально мы&nbsp;открывали розничные
				магазины по&nbsp;продаже чая и&nbsp;кофе. Потом ставили кнопочные
				кофемашины.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconOne)}>
				<img
					className={classNames(styles.icon, styles.icon__one)}
					src={PromptIconOne}
					alt="Иконка 1"
					loading="lazy"
				/>
				<p>Do, bro — это «делай, брат» или что-то про добро и тепло</p>
			</div>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent,
					styles.mediaContent__rotateLeft,
					styles.mediaContentOne
				)}
			>
				<Fancybox>
					<a
						data-fancybox="image1"
						href={MediaContentOne}
						className={classNames(styles.media, styles.fancy225)}
					>
						<img
							className={styles.coverImg}
							src={MediaContentOne}
							alt="Интерьер кофейни do.bro coffee"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descOne)}>
					За год мы вернули 60% вложенной суммы
				</p>
			</div>
		),
		textarea2: (
			<>
				Когда мы начали готовить кофе у себя, меня познакомили с HR-директором
				«Старбакса» Анной Савельевой — она очень помогла, дала много советов.
				Посоветовала купить книгу Говарда Шульца «Как чашка за чашкой строилась
				Starbucks». Я быстро прочитал её, и это был хороший пример. За год мы
				вернули 60% вложенной суммы. Какое-то время ещё просуществовали с теми
				партнёрами, которые нам доверили деньги, и в 2016 году с ними
				расстались. Это было перед тем, как мы начали масштабировать проект по
				франчайзингу.
			</>
		),
	},
	{
		heading: "Как появилось название DO.BRO",
		textarea: (
			<>
				Мы хотели зарегистрировать торговую марку «Чайкофский», но оказалось,
				что сделать так нельзя — уже есть такой сахар. Устроили мозговой штурм с
				командой, чтобы придумать новое название. Самым подходящим оказалось
				название DO.BRO. Тогда было в моде что-то со словом «бро», кто-то
				сказал: «А может “добро”». Я предложил разделить на «до» и «бро». Для
				людей до 30 лет, это некий призыв к действию: Do, bro — делай, брат. А
				для аудитории постарше — что-то про добро и тепло.
			</>
		),
	},
	{
		heading: "DO.BRO — это проект про кофе без снобизма",
		textarea: (
			<>
				Однажды мне сказали, что у каждой нормальной компании, должна быть
				миссия, и мы решили ее придумать. Наша миссия — в том, чтобы люди,
				которые приходят в кофейню, не ощущали дискомфорта, выпивали вкусную
				чашку кофе и оставались собой. Быть самим собой — значит уметь
				насладиться моментом, чувствовать радость общения и вкус жизни. И ничего
				не должно этому мешать.
			</>
		),
		mediaContent: (
			<div className={classNames(styles.mediaContent, styles.mediaContentTwo)}>
				<Fancybox>
					<a
						data-fancybox="image2"
						href={MediaContentTwo}
						className={classNames(styles.media, styles.fancy225)}
					>
						<img
							className={styles.coverImg}
							src={MediaContentTwo}
							alt="Работа бариста в кофейне"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descTwo)}>
					Наша миссия — чтобы люди выпивали вкусную чашку кофе и оставались
					собой
				</p>
			</div>
		),
		textarea2: (
			<>
				В кофейном мире много снобизма. Приходит человек в кофейню, а его
				пытаются нагрузить информацией: «анаэробная ферментация», «мытая Кения».
				И человек думает: «Зачем вы меня унижаете?! Дайте мне вкусную чашку
				кофе, я дам вам денег и пойду». Мы хотим, чтобы люди чувствовали себя
				комфортно у нас, получали максимально качественный продукт и
				возвращались снова.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconTwo)}>
				<img
					className={classNames(styles.icon, styles.icon__two)}
					src={PromptIconTwo}
					alt="Иконка 2"
					loading="lazy"
				/>
				<p>С друзьями надо быть друзьями, а не строить бизнес</p>
			</div>
		),
	},
	{
		heading: "Мы решили сделать франшизу, хотя в нас никто не верил",
		textarea: (
			<>
				В 2016 году мы приняли решение запустить проект по франчайзингу. Нужна
				была какая-то мотивация, большая цель, к которой мы будем идти. Я мечтал
				построить федеральную компанию родом с Дальнего Востока. Мне говорили:
				«Владимир, ну, какой бизнес в Благовещенске? Начнешь продавать франшизу,
				будут не компанию изучать, а карту смотреть и разбираться, где
				Благовещенск находится, и как туда добраться». Нас это не остановило, и
				мы стали развивать франчайзинговую сеть.
				<br />
				<br />
				Первые точки по нашей франшизе открывали друзья, и это дело
				неблагодарное. Они думают, что делают тебе одолжение — тестируют твою
				франшизу за свои деньги. Со всеми, с кем мы начинали франчайзинговый
				бизнес, разошлись. Кто-то закрылся, у кого-то мы выкупили проекты. С
				друзьями надо быть друзьями, а не строить бизнес.
			</>
		),
	},
	{
		heading: "С какими франчайзи есть сложности и с кем мы не работаем",
		textarea: (
			<>
				Мы не берём в партнёры франчайзи со 100% заёмными средствами. Был опыт,
				когда поняли — это отдаляет людей от цели вернуть деньги и начать
				зарабатывать.{" "}
				<strong>Не любим, когда покупают франшизы в подарок</strong>, — детям,
				жёнам, любовницам. Одно дело, когда человек сам хочет открыть бизнес,
				другое — когда ему это навязали. Ничего хорошего из этого не выйдет.
				<br />
				<br />
				Самое больное — когда ставят управлять франшизами жён. Живёт женщина в
				спокойном мире, воспитывает детей, ездит на Бали, муж занимается
				бизнесом. Он решает создать жене небольшой бизнес — чтобы она была
				занята делом и его не пилила дома — и покупает жене франшизу. Начинаются
				американские горки: «Учиться кофе варить? Учиться управлять проектом?
				Мне муж об этом не говорил», «А можно мне открыть кофейню не по вашему
				брендбуку, а в лёгких пастельных тонах? Мы на Кипре отдыхали, я вам
				сейчас фото покажу, мне вот так сделайте». Франчайзинг — история про
				партнёрство. Когда франчайзи не заинтересован в своём деле, не понимает
				сути франшизы, вероятность успеха мала.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconThree)}>
				<img
					className={classNames(styles.icon, styles.icon__three)}
					src={PromptIconThree}
					alt="Иконка 3"
					loading="lazy"
				/>
				<p>Когда франчайзи не заинтересован в деле — вероятность успеха мала</p>
			</div>
		),
	},
	{
		heading: "Каждая закрытая точка — наша боль",
		textarea: (
			<>
				Франчайзинговый рынок в России развивается в двух направлениях. Есть
				проекты, которые выходят на рынок, чтобы продать франшизы, собрать
				какой-то объём денег и закрыться. В таких франшизах кроме хорошего
				нейминга ничего нет. Они не вкладываются во «внутрянку» франшизы, в
				работу над стандартами и с партнёрами. Им всё равно, что будет с
				франчайзи дальше — своё уже заработали. Это не наша история. Мы хотим
				масштабироваться за счёт франчайзинга, развить сеть и долго зарабатывать
				вместе с нашими партнёрами.
			</>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent,
					styles.mediaContent__rotateLeft,
					styles.mediaContentThree
				)}
			>
				<Fancybox>
					<a
						data-fancybox="image3"
						href={MediaContentThree}
						className={classNames(styles.media, styles.fancy225)}
					>
						<img
							className={styles.coverImg}
							src={MediaContentThree}
							alt="Руководство для франчайзи"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descThree)}>
					Так выглядит наше руководство для франчайзи
				</p>
			</div>
		),
		textarea2: (
			<>
				Мы вкладываем много сил, энергии, времени и денег в работу над
				франшизой. И если какая-то точка закрывается — это очень неприятно.
				Серьёзные предприниматели при покупке франшизы смотрят на цифры: какое
				количество точек работает, какой поток, какая доходность у компании, а
				также на то, какое количество точек закрылось.
				<br />
				<br />
				<strong>
					Наша цель — чтобы все партнёры работали по финансовому плану, который
					запланирован, достигали нужных цифр.
				</strong>{" "}
				И чтобы процент закрывшихся партнёров был ниже, чем сейчас. Для этого
				нужно, в том числе, тщательнее выбирать франчайзи, и скоро мы запустим в
				работу регламент, по какому принципу будем их отбирать.
			</>
		),
	},
	{
		heading: "Просто встать на стихийный трафик уже не получится",
		textarea: (
			<>
				В начале 2000-х можно было поставить точку на офигенном трафике и люди
				покупали. Сейчас это не работает. Вы должны давать качественную услугу,
				захватывать гостя в свою клиентскую базу и работать с ним дальше.
				Возврат гостя обеспечивает стабильное существование бизнеса. 20%
				постоянных клиентов приносят 80% выручки.
				<br />
				<br />
				Чек вернувшегося гостя на 10% выше нового. Новый гость исследует вас и
				не готов тратить много. У вернувшегося — процент доверия выше, он готов
				потратить больше. А потом он станет адвокатом бренда, начнёт сторис
				выкладывать, ходить с брендированным стаканом, клеить наклейки на
				телефон и будет приводить вам новых гостей. Привлекать новых клиентов
				дорого.{" "}
				<strong>
					В нашей кофейне привлечение одного нового клиента стоит 321 рубль.
				</strong>{" "}
				Проще первую чашку дать в подарок, в обмен на данные гостя. Он получает
				позитивный опыт потребления, а мы контакты и возможность начать
				коммуникацию. Чем больше контактов захвачено, тем больше будет покупок и
				больше постоянных клиентов.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconFour)}>
				<img
					className={classNames(styles.icon, styles.icon__four)}
					src={PromptIconFour}
					alt="Иконка 4"
					loading="lazy"
				/>
				<p>Возврат гостя обеспечивает стабильное существование бизнеса</p>
			</div>
		),
	},
	{
		heading:
			"Чтобы наработать базу постоянных клиентов, надо чётко соблюдать стандарты",
		textarea: (
			<>
				Мы требуем от франчайзи строго соблюдения стандартов: как готовить кофе,
				как общаться с клиентами, как работать с программой лояльности. Недавно
				разошлись с одним из партнёров из-за несоответствия стандартам. Партнёр
				полностью обесценила продукт — начала дисконтировать и пытаться работать
				в дешёвой ценовой категории. Смотрю аналитику, у неё на первом месте по
				продажам стоит стандартный капучино. Спрашиваю, почему не повышают чек и
				не продают средний и большой. Говорит, что для людей это дорого. При
				этом в шаговой доступности есть три проекта, которые работают в нашей
				ценовой категории, и у них нормальные показатели, и нет никакой проблемы
				с трафиком.
			</>
		),
		mediaContent: (
			<div className={classNames(styles.mediaContent, styles.mediaContentFour)}>
				<Fancybox>
					<a
						data-fancybox="image4"
						href={MediaContentFour}
						className={classNames(styles.media, styles.fancy220)}
					>
						<img
							className={styles.coverImg}
							src={MediaContentFour}
							alt="Кофейня do.bro coffee"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descFour)}>
					Когда человек не верит в продукт, он не сможет его продать
				</p>
			</div>
		),
		textarea2: (
			<>
				Начал разбираться. Оказалось: она сама любит пить стандартный капучино,
				а всё другое для неё дорого. Поэтому она не может продать что-то другое
				гостю. Когда человек не верит в продукт и стандарты обслуживания, он не
				может это линейным сотрудникам донести. А те в итоге не могут донести
				это гостю и продать продукт.{" "}
				<strong>
					В конце месяца мы всегда проводим аналитику по проектам.
				</strong>{" "}
				Смотрим, у кого положительная динамика, у кого — наоборот. Начинаем
				разбираться, в чём проблема. Смотрим, где мы не дорабатываем как
				управляющая компания, и где у партнёра есть слабые места. Франчайзи
				должен быть готов их отрабатывать.
			</>
		),
	},
	{
		heading: "Франшиза — не волшебная таблетка",
		textarea: (
			<>
				Франшиза — всё-таки партнёрство. Был у нас франчайзи из Якутска. На
				следующий день после того, как уехала наша команда открытия, партнёр
				переехал жить в другой город. Через полгода удивился, почему показатели
				не растут. Стали разбираться. Говорит, что делают всё, как надо. В ходе
				разговора выясняется — человек находится в другом городе и контролирует
				всё через переписку с сотрудниками.
			</>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent,
					styles.mediaContent__rotateLeft,
					styles.mediaContentFive
				)}
			>
				<Fancybox>
					<a
						data-fancybox="image5"
						href={MediaContentFive}
						className={classNames(styles.media, styles.fancy220)}
					>
						<img
							className={styles.coverImg}
							src={MediaContentFive}
							alt="Франшиза кофейни"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descFive)}>
					Когда только заходишь в бизнес, первые 100 дней ты должен находиться
					на проекте
				</p>
			</div>
		),
		textarea2: (
			<>
				Можно, конечно, управлять бизнесом удалённо. Но когда ты только заходишь
				в бизнес, и у тебя ещё нет опыта, ты должен находиться на проекте.
				Первые 100 дней точно. У тебя есть теоретическая база, которую ты
				получил на обучении от нас, но нужно получить практический опыт и
				понять, как эта теория работает конкретно в твоём проекте. Если ты
				регулярно смотришь управленческую отчётность, ежедневно заглядываешь в
				систему автоматизации, анализируешь показатели и на основе них
				принимаешь решения, всё будет, как надо.
			</>
		),
	},
	{
		heading: "Как достигать нужных показателей ",
		textarea: (
			<>
				Мы сейчас внимательно контролируем каждого партнёра. У каждого есть свой
				финансовый план, который формируется исходя из затрат, которые он понёс,
				открывая проект. Этот финплан дробится на временные периоды: год, месяц,
				неделю и день.{" "}
				<strong>
					Партнёр выставляет ежедневные показатели и цели своей команде.
				</strong>{" "}
				Если он делает это ежедневно, в правильном формате с командой
				отрабатывает, то вероятность того, что они будут их достигать, сильно
				вырастает. Иначе команда будет жить своей жизнью и работать без связи с
				целями компании.
				<br />
				<br />
				Смотреть надо выручку, количество чеков, количество проданных десертов и
				позиций в чеке. Если там одна позиция — это не хорошо, идеально — три.
				Количество подключений к программе лояльности и количество списаний,
				прошедших через программу лояльности, тоже важно. Увеличение всех этих
				показателей гарантирует прибыль.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconFive)}>
				<img
					className={classNames(styles.icon, styles.icon__five)}
					src={PromptIconFive}
					alt="Иконка 5"
					loading="lazy"
				/>
				<p>
					Выручка, чеки, проданных десерты, позиции в чеке — увеличение этих
					показателей гарантирует прибыль
				</p>
			</div>
		),
	},
	{
		heading: "Кофеен много, но рынок всё ещё растёт ",
		textarea: (
			<>
				Сейчас рынок продолжает расти и ему есть куда развиваться. Если смотреть
				потребление кофе в России, мы всё ещё не достигаем показателей других
				стран. В Норвегии, Финляндии, Швеции потребление кофе достигает 11 кг на
				душу населения в год, в Америке — 5–6 кг, в Европе — 4–5 кг.{" "}
				<strong>
					В России — только 2,5 кг на душу населения, поэтому мы как минимум
					можем удвоиться.
				</strong>{" "}
				Сейчас хорошо развивают культуру потребления кофе фикс прайс-проекты.
				Люди, которые попили недорогой кофе и поняли его вкус, начинают
				переходить от количества к качеству. И мы являемся одним из тех
				проектов, которые гарантируют качество за доступную цену.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconSix)}>
				<img
					className={classNames(styles.icon, styles.icon__six)}
					src={PromptIconSix}
					alt="Иконка 6"
					loading="lazy"
				/>
				<p>Первопроходцем быть сложно, зато можно застолбить своё место</p>
			</div>
		),
	},
	{
		heading: "Почему не открываем проекты в центре Москвы",
		textarea: (
			<>
				Получили негативный опыт: две точки в центре Москвы закрыли, одна — в
				полуживом состоянии. Центр, который раньше был проходным, больше не
				работает, там нет трафика.{" "}
				<strong>
					Пандемия внесла жёсткие коррективы в потребление кофе в больших
					городах.
				</strong>{" "}
				Люди перешли на удалёнку, стали больше времени проводить в спальных
				районах. Человек, который живёт в Питере или Москве, теперь ищет объём
				услуг в рамках 15-минутной шаговой доступности.
				<br />
				<br />
				Работают новые спальники с современным жильём: Реутов, Королёв,
				Саларьево. Там отлично себя показывают детские зоны. В Реутове у нас
				есть детский кофейный бар. Пока мама пьёт кофе, ребёнок играет в
				бариста, «варит» латте. Мамы знают, что это их уголок спокойствия, они
				могут оставить ребёнка и расслабиться на 15 минут за чашкой кофе.
			</>
		),
		mediaContent: (
			<div className={styles.mediaContent__container}>
				<div className={styles.mediaContent}>
					<Fancybox>
						<a
							data-fancybox="image6"
							href={MediaContentSix}
							className={styles.media}
						>
							<img
								src={MediaContentSix}
								alt="детский уголок кофейни"
								loading="lazy"
							/>
						</a>
					</Fancybox>

					<p className={classNames(styles.desc, styles.descSix)}>
						Детский уголок в Реутове
					</p>
				</div>
				<div className={styles.mediaContent}>
					<Fancybox>
						<a
							data-fancybox="image7"
							href={MediaContentSeven}
							className={styles.media}
						>
							<img
								src={MediaContentSeven}
								alt="кофейный аппарат"
								loading="lazy"
							/>
						</a>
					</Fancybox>
					<p className={classNames(styles.desc, styles.descSeven)}>
						Пока мама пьёт кофе, ребёнок «варит» латте
					</p>
				</div>
			</div>
		),
	},
	{
		textarea: (
			<>
				<strong>В других городах можно развиваться во все стороны.</strong> И с
				точки потребления кофе в вендингах-аппаратах, и в кофе-поинтах.
				Минимальный город, в котором мы открывались и достигли успеха, — с
				населением 70 000 человек. В регионах франчайзи надо быть готовыми к
				тому, что они будут первопроходцами, и какие-то вещи будут
				восприниматься гостями неоднозначно. Но всё равно в каждом городе есть
				определённая категория людей, которые путешествуют, в том числе по
				России. Они приезжают в Москву и Питер, смотрят как там и хотят, чтобы в
				их городе было также.
				<br />
				<br />
				Когда даёшь качество как в Москве и Санкт-Петербурге, у тебя быстро
				появляется постоянная клиентская база. Любой потребитель в регионах
				ожидает от проекта трёх вещей: хороший сервис, хорошее качество и
				возможность сэкономить. Если ты все эти триггеры закрываешь, то гость
				будет возвращаться. Первопроходцем быть всегда сложно, зато ты можешь
				застолбить своё место.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconSeven)}>
				<img
					className={classNames(styles.icon, styles.icon__seven)}
					src={PromptIconSeven}
					alt="Иконка 7"
					loading="lazy"
				/>
				<p>Масштабировать нецифровизированный бизнес невозможно</p>
			</div>
		),
	},
	{
		heading: "Собственная обжарка зерна",
		textarea: (
			<>
				Мы системная компания и хотим контролировать все процессы. Поняли, что
				тот кофе, который к нам приходит, мы контролировать не можем, не знаем,
				как его обжаривают. Подумали, что могли бы закупать зелёное зерно и
				обжаривать его сами. Изучили вопрос и поняли, что нам под силу это
				делать. Теперь мы контролируем качество нашего продукта не от готового
				зерна до чашки, а от зелёного зерна до чашки.{" "}
				<strong>
					У нас есть мечта — контролировать кофе от ростка до чашки,
				</strong>{" "}
				чтобы у нас были свои люди на плантациях, и мы понимали, где кофе
				растёт, какой вкус формируется, как он обрабатывается на плантациях.
				Думаю, к этому тоже придём.
				<br />
				<br />
				Масштабировать нецифровизированный бизнес невозможно. Одна из задач
				менеджера — формирование информационной основы бизнеса. У нас вокруг
				компании строится IT-инфраструктура: система автоматизации и учёта,
				система цифровизации, контроля качества обжаренного зерна. IT-команда
				постоянно что-то дорабатывает и настраивает. Компаниям, которые не
				занимаются цифровизацией, осталось жить год-два.
			</>
		),
	},
	{
		heading: "Telegram-бот стал хорошим фильтром при найме бариста",
		textarea: (
			<>
				Мы сделали крутую рабочую тетрадь для бариста: с инфографикой,
				картинками, qr-кодами и ссылками на видео по теме. К концу практического
				обучения 90% потенциальных сотрудников отсеивалось, так как понимали,
				что работать с продуктом — это серьёзный труд. Вот для примера несколько
				страниц из обучающей тетради:
			</>
		),
		mediaContent: (
			<Slider
				data={[
					<Fancybox>
						<a
							data-fancybox="slider"
							href={SlideContentOne}
							className={classNames(styles.slideContent, styles.fancy220)}
						>
							<img
								className={styles.containImg}
								src={SlideContentOne}
								alt="обучающая тетрадь — фото 1"
								loading="lazy"
							/>
						</a>
					</Fancybox>,
					<Fancybox>
						<a
							data-fancybox="slider"
							href={SlideContentTwo}
							className={classNames(styles.slideContent, styles.fancy220)}
						>
							<img
								className={styles.containImg}
								src={SlideContentTwo}
								alt="обучающая тетрадь — фото 2"
								loading="lazy"
							/>
						</a>
					</Fancybox>,
					<Fancybox>
						<a
							data-fancybox="slider"
							href={SlideContentThree}
							className={classNames(styles.slideContent, styles.fancy220)}
						>
							<img
								className={styles.containImg}
								src={SlideContentThree}
								alt="обучающая тетрадь — фото 3"
								loading="lazy"
							/>
						</a>
					</Fancybox>,
				]}
			/>
		),
		textarea2: (
			<>
				Ходили с этой проблемой вокруг да около, а потом разработали обучающего
				бота в Telegram. Туда добавили весь контент из тетради. В конце бота
				сделали тест. Те, кто его проходят, получают приглашение на практику.
				Бот стал хорошим фильтром. Те люди, которые реально приходят работать с
				кофе, всё в боте проходят и изучают. И мы потом на практике даём навыки,
				которые позволяют им делать классный кофе. Те, кто приходят временно, на
				этом боте понимают, что это не их дело, отсеиваются. Мы на них не тратим
				время и ресурсы, и найм становится дешевле.
			</>
		),
	},
	{
		heading:
			"Система автоматизации и учёта — продукт, который должен быть в каждой компании",
		textarea: (
			<>
				Мы используем автоматизацию Quick Resto. Это даёт возможность управлять
				сетью. У нас есть материнское франчайзинговое облако, где подгружена вся
				номенклатура, и мы партнёров туда подключаем, и они уже с этой
				номенклатуры забирают тот объём меню, который им нужен для работы. Там
				всё это уже автоматизировано. Quick Resto даёт нам открытый API, что
				немаловажно. Мы сейчас доделываем своё мобильное приложение, которое
				должно улучшить нашу работу, сервис и скорость отдачи чашки. Через
				мобильное приложение можно будет делать предзаказ, оплачивать и получать
				лояльность. С хорошим контентом, чтобы это было инструментом высокого
				сервиса и хорошего обслуживания.
			</>
		),
		mediaContent: (
			<div
				className={classNames(styles.mediaContent, styles.mediaContentEight)}
			>
				<Fancybox>
					<a
						data-fancybox="image8"
						href={MediaContentEight}
						className={classNames(styles.media, styles.fancy220)}
					>
						<img
							className={styles.coverImg}
							src={MediaContentEight}
							alt="Касса quick resto"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descEight)}>
					Quick Resto даёт возможность управлять сетью
				</p>
			</div>
		),
		textarea2: (
			<>
				Автоматизация помогает нам думать системно. Там много отчётов и если
				разобраться, погрузиться досконально в аналитику, ты можешь из неё
				выкручивать те данные, которые помогают тебе перестроить работу.
				Например, можно посмотреть, какие напитки у тебя продаются. Если у тебя
				продаются стандартные напитки, то с помощью скрипта и работы сотрудников
				можно сделать так, чтобы клиенты покупали напитки объёмом больше.
				Получишь маржинальность больше даже на том трафике, который у тебя уже
				есть.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconEight)}>
				<img
					className={classNames(styles.icon, styles.icon__eight)}
					src={PromptIconEight}
					alt="Иконка 8"
					loading="lazy"
				/>
				<p>Люди — основной капитал компании</p>
			</div>
		),
	},
	{
		heading: "Советы тем, кто хочет открыть свою кофейню",
		textarea: (
			<>
				<strong>Считайте цифры.</strong> Нужно взять финмодель, покрутить её со
				всех сторон. Посчитать оптимистичный план, реалистичный и
				пессимистичный. Если при пессимистичных прогнозах вы понимаете, что у
				вас модель работает, можно заходить в бизнес.
				<br />
				<br />
				<strong>Учитесь работать с продуктом.</strong> Предприниматель должен
				понимать, что такое кофе, как с ним работать, как он выращивается, как
				его обрабатывают и как всё это влияет на вкус. Наши партнёры проходят
				углублённый курс по продукту, чтобы потом, работая со своей линейной
				командой они понимали, что это команда делает, могли заменить кого-то из
				сотрудников, если он куда-то уходит. И чтобы они могли обучать сами.
				<br />
				<br />
				<strong>
					Подумайте, готовы ли вы платить за ошибки деньгами и временем.
				</strong>{" "}
				Проблем с информацией сейчас нет. В интернете можно найти всё, что
				угодно, и обучиться чему угодно. Курсов по обжарке и варению кофе по
				международным стандартам сейчас много. Но тут вопрос в том, готов ли ты
				развиваться индивидуально, совершать ошибки и тратить на это время и
				деньги, или ты хочешь развиваться внутри компании и быть частью большого
				проекта. Во втором случае лучше подойдёт франшиза.
				<br />
				<br />
				<strong>Цените людей. Люди — основной капитал компании.</strong> И
				партнёры, и сотрудники, и те, кто пользуются вашими услугами. Если
				сотрудники будут работать в комфортных условиях, они будут давать
				качественный продукт. Ту же площадку выбираете — надо посмотреть, как
				вашему сотруднику там будет работаться. Если он будет сидеть и замерзать
				там, вряд ли он будет варить вкусный кофе.
				<br />
				<br />
				<strong>Собирайте обратную связь от сотрудников.</strong> Мы всегда
				собираем идеи, рациональное собираемся внедрять. Например, три последние
				напитка, которые мы ввели в меню, это предложения от наших бариста из
				проектов-партнёров.
				<br />
				<br />
				<strong>Делайте хорошо или не делайте никак.</strong> Я своим ребятам
				всегда говорю, мы или делаем отлично, или вообще не делаем. Потому что
				промежуточное состояние, когда то ли сделали, то ли нет, не приведёт к
				успеху.
			</>
		),
	},
]
