import React, { useState } from "react"
import classNames from "classnames"

import { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import styles from "./styles.module.scss"

interface Props {
	className?: string
	data: JSX.Element[]
}

// Слайдер для артилка
// Пример: /secret/dobrecoffee
// Оверлей нужен, посколько клиент захотел, чтоб при хвере на слайдер кнопки появлялилсь,
// но они лежат в ней свайпера и область видимости теряется для ховера, поэтому пришлось изобрести велосипед
export default function Slider(props: Props) {
	const data = props.data || []

	const [isButtonVisible, setIsButtonVisible] = useState(false)

	const onMouseEnter = () => {
		setIsButtonVisible(true)
	}

	const onMouseLeave = () => {
		setIsButtonVisible(false)
	}

	return (
		<>
			<div className={styles.swiperOverlay} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}></div>
			<div className={styles.swiperContainer} onMouseEnter={onMouseEnter}>
				<Swiper
					className={styles.swiperSlider}
					rewind={true}
					modules={[Navigation, Pagination]}
					pagination={{
						clickable: true,
						clickableClass: styles.swiperPagination,
					}}
					navigation={{
						nextEl: ".next",
						prevEl: ".prev",
					}}
				>
					{data.map(slide => (
						<SwiperSlide className={styles.swiperSlide}>{slide}</SwiperSlide>
					))}
				</Swiper>

				<button
					type="button"
					className={classNames(
						styles.swiperButton,
						styles.swiperButtonLeft,
						{
							[styles.swiperButtonVisible]: isButtonVisible,
						},
						"prev"
					)}
					// onMouseEnter={onMouseEnter}
					// onMouseLeave={onMouseLeave}
				>
					<svg
						width="11"
						height="21"
						viewBox="0 0 11 21"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M10 1.19922L1.00024 10.199"
							stroke="#CCCCCC"
							stroke-width="2"
							stroke-linecap="round"
						/>
						<path
							d="M1 10.1992L9.99992 19.1991"
							stroke="#CCCCCC"
							stroke-width="2"
							stroke-linecap="round"
						/>
					</svg>
				</button>

				<button
					type="button"
					className={classNames(
						styles.swiperButton,
						styles.swiperButtonRight,
						{
							[styles.swiperButtonVisible]: isButtonVisible,
						},
						"next"
					)}
					// onMouseEnter={onMouseEnter}
					// onMouseLeave={onMouseLeave}
				>
					<svg
						width="11"
						height="21"
						viewBox="0 0 11 21"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M1 1.19922L9.99976 10.199"
							stroke="#CCCCCC"
							stroke-width="2"
							stroke-linecap="round"
						/>
						<path
							d="M10 10.1992L1.00008 19.1991"
							stroke="#CCCCCC"
							stroke-width="2"
							stroke-linecap="round"
						/>
					</svg>
				</button>
			</div>
		</>
	)
}
