import React from "react"

import MainBannerLogo from "./assets/MainBannerLogo.webp"
import MediaMainBanner from "./assets/MediaMainBanner.webp"
import MediaMainBannerMobile from "./assets/MediaMainBannerMobile.webp"
import DesktopMediaMainBanner from "./assets/DesktopMediaMainBanner.webp"

import styles from "./styles.module.scss"

export const mainBanner = {
	banner: {
		mediaContent: (
			<>
				<img
					className={styles.mainBanner__desktop}
					src={MediaMainBanner}
					alt="Франшиза кофеен DO.BRO Coffee"
					loading="lazy"
				/>
				<img
					className={styles.mainBanner__tablet}
					src={MediaMainBannerMobile}
					alt="Франшиза кофеен DO.BRO Coffee"
					loading="lazy"
				/>
			</>
		),
		desktopBanner: (
			<img
				src={DesktopMediaMainBanner}
				alt="Франшиза кофеен DO.BRO Coffee"
				loading="lazy"
			/>
		),
		logo: (
			<img
				className={styles.logo}
				src={MainBannerLogo}
				alt="Логотип"
				loading="lazy"
			/>
		),
		quote: (
			<>
				Как небольшой проект с Дальнего Востока превратился в сеть с 50+ точек.{" "}
				<span className={styles.heading}>
					Кейс <span className={styles.quote}>DO.BRO Coffee</span>
				</span>
			</>
		),
	},
	heading: "Как спортсмен с Дальнего Востока создал сеть кофеен по всей России",
	textarea: (
		<p>
			Основатель компании DO.BRO Coffee Владимир Михеев занимается кофе 17 лет.
			Начинал с точки по продаже чая и кофе в Благовещенске. Сейчас DO.BRO
			Coffee имеет собственную сеть кофеен и сеть партнёров-франчайзи, всего —
			56 точек. А также своё производство по обжарке кофе и школу бариста.
			Владимир рассказал, как начинал бизнес и что помогло масштабироваться, с
			какими партнёрами не работает, как достигать нужных показателей и что
			значит кофе без снобизма. А также дал советы тем, кто хочет запустить свой
			проект. 
		</p>
	),
	video: {
		src: "https://www.youtube.com/embed/-oZV7C_PtKU",
		textarea: (
			<p className={styles.textarea}>
				У нас привлечение одного нового клиента стоит 321 рубль
			</p>
		),
	},
}
